import React, { useState, useCallback, useEffect } from "react";

import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Input
} from "reactstrap";

//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import FeatherIcon from "feather-icons-react";

import Loader from "../../components/Shared/Loader";

import { Link, useParams, useNavigate } from "react-router-dom";

import * as moment from "moment";

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import shape from '../../assets/images/bg.png';
import { Timeline } from 'rsuite';
import CheckCircle from '@rsuite/icons/legacy/CheckCircle';
import CloseCircle from '@rsuite/icons/legacy/CloseCircle';

import { optiroute } from "../../config";

import OrderStatus from "../../components/Shared/OrderStatus";


import {
	useGetTrackingInfoQuery
} from '../../api/api';


const TrackingDetails = () => {

	//const { executeRecaptcha } = useGoogleReCaptcha();

	document.title = "Seguimiento en línea de pedidos Pacari Express";
	
	const navigate = useNavigate();

	let { reference } = useParams();

	const [search, setSearch] = useState(null);
	//const [token, setToken] = useState(null);
	const [searchPressed, setSearchPressed] = useState(false);

	const { 
		data: order,
		error,
		isFetching: orderLoading,
		isSuccess: orderSuccess
	} = useGetTrackingInfoQuery(
		{ 
			reference: reference,
			enterprise: optiroute.ENTERPRISE_UUID
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !reference,
		}
	);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM HH:mm");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const onSearchPressed = useCallback((value) => {
		if(search && search !== ""){
			setSearchPressed(true);
			navigate(`../tracking/${search}`);
		}
	});

	const onSearchChange = useCallback((value) => {
		setSearchPressed(false);
		setSearch(value);
	});

	/*useEffect(() => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return;
		}
	
		const handleReCaptchaVerify = async () => {
			const token = await executeRecaptcha('tracking');
			setToken(token);
		};
	
		handleReCaptchaVerify();

	}, [executeRecaptcha]);*/

	useEffect(() => {
		if (error) {
			setSearchPressed(false);
		}
	}, [error]);

	useEffect(() => {
		if (order) {
			debugger;
			setSearchPressed(false);
		}
	}, [order]);

	/*useEffect(() => {
		if (token) {
			debugger;
		}
	}, [token]);*/

	return (
		<React.Fragment>
			{/*<NavbarPage />*/}{/*"bg-half-170 bg-light d-table w-100*/}
			<section
				className={(orderSuccess || error) ? "bg-half-100 bg-light d-table w-100" : "bg-home d-flex align-items-center"}
				style={{ backgroundImage: `url(${shape})` }}
				>
				<Container>
					<Row className="justify-content-center mb-0 pb-0">
						<div className="title-heading text-center">
							<span className="text-muted">La transparencia es clave para nosotros</span>
							<h4 className="mt-2 mb-4">Realiza el seguimiento de tu pedido <span className="text-primary">aquí</span></h4>
							<p className="text-muted para-desc mx-auto mb-0">Ingresa a continuación el código de seguimiento de tu pedido</p>

							<div className="text-center subcribe-form mt-4 pt-2">
								<form>
									<div className="form-icon position-relative">
										<FeatherIcon
											icon="search"
											className="fea icon-sm icons mt-1"
										/>
										<Input
											type="search"
											id="order-code"
											className="rounded-pill bg-white-50 form-control ps-5"
											placeholder="Código de pedido"
											defaultValue={reference}
											onChange={(e) => onSearchChange(e.target.value)}
										/>

									</div>
									<button
										className="btn btn-pills btn-primary"
										type="submit"
										disabled={searchPressed}
										onClick={(e) => {
											onSearchPressed(e.target.value);
										}}
									>
										<i className="ri-search-2-line align-bottom me-1"></i>{" "}
										Buscar
									</button>
								</form>
							</div>
						</div>
					</Row>
					{/**/}
				</Container>
			</section>
			
			{(orderSuccess || error) && (
				<section className="section mt-60">
					<Container className="mt-lg-3">
						<Row>

							<Col >
								{/*<div className="border-bottom pb-4">
									<h5>Importante</h5>
									<p className="text-muted mb-0">
									Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
									</p>
								</div>*/}
								<div className="features-absolute">
									{orderLoading && <Loader/>}
									{orderSuccess && (
										<Card
											className="public-profile border-0 rounded shadow px-md-3"
											style={{ zIndex: "1" }}
											>
											<CardBody>
												<Row className="align-items-center">
													<Col lg="2" md="3" className="text-md-start text-center">
														<FeatherIcon
															icon="package"
															className="avatar avatar-large rounded-circle shadow d-block mx-auto p-4 text-primary bg-light"
														/>
													</Col>

													<Col lg="10" md="9">
														<Row className="align-items-end">
															<Col
																md="6"
																className="text-md-start text-center mt-4 mt-sm-0">
																<h3 className="title mb-0">Pedido: {order.reference} </h3>
																
																<div className="d-flex align-items-center">                        
																	<FeatherIcon
																	icon="user"
																	className="fea icon-ex-md text-muted me-3"
																	/>                        
																	<small className="text-muted h6 pt-2">
																		{order.customer.name}
																	</small>
																</div>
																<Col>
																	<OrderStatus order={order}/>
																</Col>
															</Col>
															<Col md="5" className="text-md-end text-center">
																<p className="text-muted mb-0">Fecha de actualización {(handleValidDate(order.updated_at))}</p>
															</Col>
														</Row>
													</Col>
												</Row>
											</CardBody>
										</Card>
									)}
									{error && (
										<Card
											className="public-profile border-0 rounded shadow px-md-3"
											style={{ zIndex: "1" }}>
											<CardBody>
												<Row className="align-items-center">
													<Col lg="2" md="3" className="text-md-start text-center">
														<FeatherIcon
															icon="frown"
															className="avatar avatar-large rounded-circle shadow d-block mx-auto p-4 text-primary bg-light"
														/>
													</Col>

													<Col lg="10" md="9">
														<Row className="align-items-end">
															<Col
																md="6"
																className="text-md-start text-center mt-4 mt-sm-0">
																<h3 className="title mb-0">No hemos encontrado el pedido solicitado</h3>
																
															</Col>
														</Row>
													</Col>
												</Row>
											</CardBody>
										</Card>
									)}
								</div>

								{orderSuccess && (
									<div className="border-bottom p-4">
										<Row>
											<Col md="6" className="mt-4">
												<h5>Información de Entrega:</h5>
												<div className="mt-4">
													<div className="d-flex align-items-center mt-3">                        
														<FeatherIcon
														icon="map-pin"
														className="fea icon-ex-md text-muted me-3"
														/>                        
														<div className="flex-1">
															<h6 className="text-primary mb-0">Dirección :</h6>
															<Link to="#" className="text-muted">
																{order.address.short_address}
															</Link>
														</div>
													</div>
													{(order.customer.phone) && (
														<div className="d-flex align-items-center mt-3">                       
															<FeatherIcon
															icon="phone"
															className="fea icon-ex-md text-muted me-3"
															/>                       
															<div className="flex-1">
																<h6 className="text-primary mb-0">Teléfono:</h6>
																<Link to="#" className="text-muted">
																	{order.customer.phone}
																</Link>
															</div>
														</div>
													)}
												</div>
											</Col>

											<Col lg="6" className="mt-4 pt-2 pt-sm-0">
												<h5>Eventos:</h5>
												<div className="mt-4">
													<Timeline>
														{(order.created_at) && (
															<Timeline.Item dot={<CheckCircle style={{ color: 'green' }} />}>
																{(handleValidDate(order.created_at))} - <span className="h6 text-primary">Pedido Creado</span>
															</Timeline.Item>
														)}
														{(order.scheduled_at ) && (
															<Timeline.Item dot={<CheckCircle style={{ color: 'green' }} />}>
																{(handleValidDate(order.scheduled_at))} - <span className="h6 text-primary mb-0">Sucursal de destino</span>
															</Timeline.Item>
														)}
														{(order.waypoint && order.waypoint.route && order.waypoint.route.started_at) && (
															<Timeline.Item dot={<CheckCircle style={{ color: 'green' }} />}>
																{(handleValidDate(order.waypoint.route.started_at))} - <span className="h6 text-primary mb-0">En Ruta de entrega</span>
															</Timeline.Item>
														)}
														{(order.route_started_at ) && (
															<Timeline.Item dot={<CheckCircle style={{ color: 'green' }} />}>
																{(handleValidDate(order.route_started_at))} - <span className="h6 text-primary mb-0">En Ruta de entrega</span>
															</Timeline.Item>
														)}
														{(order.completed_at && order.status == 5) && (
															<>
																<Timeline.Item dot={<CloseCircle style={{ color: 'red' }} />}>
																	<p>{(handleValidDate(order.completed_at))} - <OrderStatus order={order}/></p>

																	{(order.waypoint && order.waypoint.reason_name) && (
																		<div className="d-flex align-items-center">                          
																			<div className="mb-0 me-2">Razón de salto: </div>                     
																			<div className="text-muted">
																				{order.waypoint.reason_name}
																			</div>
																		</div>
																	)}
																</Timeline.Item>
															</>
														)}
														{(order.completed_at && order.status == 3) && (
															<Timeline.Item dot={<CheckCircle style={{ color: 'green' }} />}>{(handleValidDate(order.completed_at))} - <span className="h6 text-primary mb-0"><OrderStatus order={order}/></span></Timeline.Item>
														)}
													</Timeline>
												</div>
											</Col>
											
										</Row>
									</div>
								)}

								{order && ((order.images && order.images.length > 0) || (order.waypoint && order.waypoint.images && order.waypoint.images.length > 0) || ((order.waypoint && order.waypoint.reception_name) || (order.waypoint && order.waypoint.reception_rut) )) && (
									<div className="p-4">
										<Row>
											<Col md="6" className="mt-5">
												<h5>Información de recepción:</h5>
												<div className="mt-4">
													{(order.waypoint.reception_name) && (
														<div className="d-flex align-items-center">                          
															<FeatherIcon
															icon="mail"
															className="fea icon-ex-md text-muted me-3"
															/>                         
															<div className="flex-1">
																<h6 className="text-primary mb-0">Nombre receptor:</h6>
																{order.waypoint.reception_name}
															</div>
														</div>
													)}
													{(order.waypoint.reception_rut) && (
														<div className="d-flex align-items-center mt-3">                        
															<FeatherIcon
															icon="map-pin"
															className="fea icon-ex-md text-muted me-3"
															/>                        
															<div className="flex-1">
																<h6 className="text-primary mb-0">RUT Receptor:</h6>
																{order.waypoint.reception_rut}
															</div>
														</div>
													)}
												</div>
											</Col>
										

											{(order.waypoint && order.waypoint.images && order.waypoint.images.length > 0) && (
												<Col md="6" className="mt-5">
													<h5>Fotografías de la entrega:</h5>
													<div className="mt-4">
														<Row id="grid">
															<ResponsiveMasonry
															columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
															>
																<Masonry columnsCount={3}>
																	{order.waypoint.images.map((item, key) => (
																		<Col key={key} className="col-12 spacing picture-item">
																			<img src={item.url} className="img-fluid shadow seo-hero-widget rounded-md" alt="" />
																		</Col>
																	))}
																</Masonry>
															</ResponsiveMasonry>
														</Row>
													</div>
												</Col>
											)}

											{(order.images && order.images.length > 0) && (
												<Col md="6" className="mt-5">
													<h5>Fotografías de la entrega:</h5>
													<div className="mt-4">
														<Row id="grid">
															<ResponsiveMasonry
															columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
															>
																<Masonry columnsCount={3}>
																	{order.images.map((item, key) => (
																		<Col key={key} className="col-12 spacing picture-item">
																			<img src={item.url} className="img-fluid shadow seo-hero-widget rounded-md" alt="" />
																		</Col>
																	))}
																</Masonry>
															</ResponsiveMasonry>
														</Row>
													</div>
												</Col>
											)}
										</Row>
									</div>
								)}
							</Col>
						</Row>
					</Container>
				</section>
			)}
			
		</React.Fragment>
	);
};

export default TrackingDetails;